var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c("contact-list", {
        attrs: {
          "masthead-title": _vm.pageTitle,
          "phone-support-contact": _vm.phoneSupportContact,
          "is-phone-contact": true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }