<template>
  <b-container>
    <contact-list :masthead-title="pageTitle" :phone-support-contact="phoneSupportContact" :is-phone-contact="true" />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ContactList from "@/pages/ContactList"

export default {
  name: "VirtualPhoneContacts",
  components: { ContactList },
  data(){
    return {
      pageTitle: 'Contacts',
      phoneSupportContact: {
        first_name: 'Phone',
        last_name: 'Customer Support',
        contact_company_name: null,
        contact_is_company: null,
        person_addresses: [],
        person_emails: [],
        person_phones: [{
          kind: 'assistant',
          label: 'New Phone',
          phone_number: '5093813920',
        }],
      },
    }
  },

  computed: {
    ...mapGetters('virtualPhones', ['selectedPhone']),
  },

  methods: {
    ...mapActions('virtualPhones', ['loadVirtualPhones']),

    addContact(){
      this.$refs['virtual-phone-add-contact-modal'].show()
    },
  },
}
</script>
